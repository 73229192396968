import { request } from "xiangxin-webC-vue";

export interface BoxIdArr {
  box_id_arr: number[];
}

/**
 * @description 将箱子添加到删除池 （删除箱子）
 * @author ZhangYù
 * @date 14/06/2022
 * @export
 * @param {BoxIdArr} data 箱子id数组
 * @return {*}
 */
export function AddBoxToDelPool(data: BoxIdArr) {
  return request({
    url: "/dispatch/match/pool/del_pool/add",
    method: "POST",
    data,
  });
}

/**
 * @description 将箱子从删除池移除 （还原箱子）
 * @author ZhangYù
 * @date 14/06/2022
 * @export
 * @param {BoxIdArr} data 箱子id数组
 * @return {*}
 */
export function DelBoxToDelPool(data: BoxIdArr) {
  return request({
    url: "/dispatch/match/pool/del_pool/drop",
    method: "POST",
    data,
  });
}

/**
 * @description 将箱子添加到待确认
 * @author ZhangYù
 * @date 14/06/2022
 * @export
 * @param {BoxIdArr} data 箱子id数组
 * @return {*}
 */
export function AddBoxToQuerenPool(data: BoxIdArr) {
  return request({
    url: "/dispatch/match/pool/queren_pool/add",
    method: "POST",
    data,
  });
}

/**
 * @description 匹配中心汇总信息
 * @author ZhangYù
 * @date 14/06/2022
 * @export
 * @return {*}  {Promise<{ count_match: number, count_queren: number }>}
 */
export function GetMatchPoolCollect(scope?: number | string): Promise<{
  count_match: number;
  count_queren: number;
}> {
  return request({
    url: "/dispatch/match/pool/collect",
    params: {
      scope,
    },
  });
}

// 匹配池，段列表
export const getWaitMatchList = (
  segment_type: number | string,
  scope: string,
  search = ""
) => {
  return request({
    url: "/dispatch/match/pool/match_pool/segment_list",
    params: {
      segment_type,
      search_code: search,
      scope,
    },
  });
};

// 待确认池，段列表
export const getWaitConfirmList = (scope: string) => {
  return request({
    url: "/dispatch/match/pool/queren_pool/segment_list",
    params: {
      scope,
    },
  });
};

// 路线列表
export const getRouteList = () =>
  request({
    url: "/dispatch/match/route/list",
  });

// 段加入路线
export const addRouteBySegment = (ids: string[], route: number) => {
  return request({
    url: "/dispatch/match/route/add/segment",
    method: "POST",
    data: {
      segment_id_arr: ids,
      route_id: route,
    },
  });
};

// 待确认添加到待匹配
export const addToMatch = (ids: string[] | number[]) =>
  request({
    url: "/dispatch/match/pool/match_pool/add",
    method: "POST",
    data: {
      box_id_arr: ids,
    },
  });

// 路线段列表
export const getRoutes = () =>
  request({
    url: "/dispatch/match/route/list/segment",
  });

// 待确认池，移除箱子
export const delConfirm = (ids: string[]) =>
  request({
    url: "/dispatch/match/pool/queren_pool/drop",
    method: "POST",
    data: {
      box_id_arr: ids,
    },
  });

// 匹配池，移除箱子
export const delMatch = (ids: string[]) =>
  request({
    url: "/dispatch/match/pool/match_pool/drop",
    method: "POST",
    data: {
      box_id_arr: ids,
    },
  });

// 路线删除
export const delRoute = (id: number) =>
  request({
    url: "/dispatch/match/route/drop",
    method: "POST",
    data: {
      route_id: id,
    },
  });
/**
 * @description 路线，3.2，箱子加入路线
 * @author ZhangYù
 * @date 22/06/2022
 * @export
 * @param {number[]} box_id_arr 箱子id arr string
 * @param {number} [route_id=0] 新的路线id，不传或为0表示新建路线
 * @return {*}
 */
export function addRouteByBox(box_id_arr: number[], route_id = 0) {
  return request({
    url: "/dispatch/match/route/add/box",
    method: "POST",
    data: {
      box_id_arr,
      route_id,
    },
  });
}

/**
 * 路线，4，段从路线中移除
 * @param ids 段id 数组
 * @returns
 */
export const delSegmentInRoute = (ids: number[] | string[]) =>
  request({
    url: "/dispatch/match/route/drop/segment",
    method: "POST",
    data: {
      segment_id_arr: ids,
    },
  });
