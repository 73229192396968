interface State {
  // 弹窗显示转改
  modelVisible: number;
  text: string;
  segList: any[];
  qiNodeType: string | null;
}
const state: State = {
  modelVisible: 0,
  // 操作文本
  text: "",
  // 段状态数据
  segList: [],
  // 段起点节点
  qiNodeType: null,
};

const mutations = {
  SET_DATA: (state: State, data: any): void => {
    const { text, segList, qiNodeType } = data;
    state.segList = segList;
    state.text = text;
    state.qiNodeType = qiNodeType;
  },
  UPDATE_MODEL_VISIBLE: (state: State, data: boolean): void => {
    state.modelVisible += 1;
  },
};

const actions = {
  updateData(context: any, data: any): void {
    context.commit("SET_DATA", data);
  },
  updateModelVisible(context: any, data: boolean): void {
    context.commit("UPDATE_MODEL_VISIBLE", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
