import { FeeConfigInterface } from "@/interface/segment";
import { SegmentItemInterface } from "xiangxin-element/dist/types/interface";
import { SegmentNodeTypes } from "@/interface/order";
import { GetBoxSegmentDetailApi } from "@/service/api";
import { GetMatchPoolCollect } from "@/service/matchCenter";
import { freeHandel } from "@/components/segment-shopping-cart/freeHandel";
interface Validate {
  // 多箱子校验
  box: boolean;
  // 相邻段时间校验
  time: boolean;
  // 相邻段操作校验
  operation: boolean;
  // 段的连续性
  continuity: boolean;
}
interface IMatchCount {
  count_match: number;
  count_queren: number;
}
interface State {
  validate: Validate;
  // 弹窗显示转改
  modelVisible: boolean;
  // 已选择的段
  selectedSegments: SegmentItemInterface[];
  // 已选择的段ID
  selectedSegmentId: string[];
  // 箱子 段ID 箱列表去放单使用
  boxSegmentId?: number | string;
  nodeType?: SegmentNodeTypes;
  freeConfig?: FeeConfigInterface[];
  // 已完成 去放单金额处理
  free_finished: boolean;
  // 费用校验
  moneyValidate: Record<string, boolean>;
  editTimeVisible: boolean; // 编辑时间的弹窗
  currentRouteData: Record<string, any>; //当前所在行的数据
  matchCenter: boolean;
  orderGrab: boolean;
  matchCenterRefresh: boolean;
  matchCount: IMatchCount;
  scope?: string;
}
const state: State = {
  validate: {
    box: true,
    time: true,
    operation: true,
    continuity: true,
  },
  modelVisible: false,
  selectedSegments: [],
  selectedSegmentId: [],
  freeConfig: [],
  free_finished: false,
  moneyValidate: {},
  editTimeVisible: false, // 编辑时间的弹窗
  currentRouteData: {}, // 当前所在行的数据
  matchCenter: false, // 是否是匹配中心
  orderGrab: false, // 是否是抢单
  matchCenterRefresh: false, // 是否更新
  matchCount: {
    count_match: 0,
    count_queren: 0,
  },
  scope: "",
};

const mutations = {
  SET_VALIDATE: (state: State, data: Validate): void => {
    state.validate = data;
  },
  UPDATE_MODEL_VISIBLE: (state: State, data: boolean): void => {
    state.modelVisible = data;
    state.selectedSegments = [];
    state.selectedSegmentId = [];
    state.boxSegmentId = undefined;
    state.nodeType = undefined;
    if (!data) {
      state.matchCenter = false;
      state.orderGrab = false;
    }
  },
  // 匹配中心打开购物车
  UPDATE_MODEL_VISIBLE_IN_MATCH_CENTER: (
    state: State,
    data: { visible: boolean; grab: boolean }
  ): void => {
    state.modelVisible = data.visible;
    state.matchCenter = true;
    state.orderGrab = data.grab;
  },
  UPDATE_MATCH_CENTER_REFRESH: (state: State, data: boolean): void => {
    state.matchCenterRefresh = data;
  },
  // 更新 去放单数据
  UPDATE_BOX_SEGNMENT_DATA: async (
    state: State,
    data: { boxSegmentId: string; nodeType: SegmentNodeTypes }
  ): Promise<void> => {
    const { boxSegmentId, nodeType } = data;
    state.boxSegmentId = data.boxSegmentId;
    state.nodeType = data.nodeType;
    state.free_finished = false;
    await GetBoxSegmentDetailApi(boxSegmentId, nodeType).then((res) => {
      const ids: string[] = [];
      const data = res.map((item) => {
        ids.push(item.segment_id);
        let obj = { ...item };
        if (state.freeConfig?.length && !state.free_finished) {
          obj = freeHandel(item, state.freeConfig) as SegmentItemInterface;
          state.free_finished = true;
        }

        obj.disabledOperation = true;
        return obj;
      });
      state.selectedSegments = data;
      state.selectedSegmentId = ids;
    });
  },
  // 更新选中的段
  UPDATE_SEGMENT: (state: State, data: any): void => {
    state.free_finished = false;
    if (Array.isArray(data)) {
      state.selectedSegments = JSON.parse(JSON.stringify(data));
      const ids = data.map((item) => item.segment_id);
      state.selectedSegmentId = ids;
    } else if (isNaN(data)) {
      const ids = [...state.selectedSegmentId, data.segment_id];
      const segments = [
        ...state.selectedSegments,
        JSON.parse(JSON.stringify(data)),
      ];
      state.selectedSegments = segments;
      state.selectedSegmentId = ids;
    } else {
      const segments: any = [];
      const ids: any = [];
      state.selectedSegments.forEach((item) => {
        if (item.segment_id != data) {
          segments.push(item);
          ids.push(item.segment_id);
        }
      });
      state.selectedSegments = segments;
      state.selectedSegmentId = ids;
    }
  },
  // 更新费用配置
  UPDATE_FREE_CONFIG: (state: State, data: FeeConfigInterface[]) => {
    state.freeConfig = data;
    if (state.selectedSegments?.length && !state.free_finished) {
      state.selectedSegments = freeHandel(
        state.selectedSegments,
        state.freeConfig
      ) as SegmentItemInterface[];
    }
    state.free_finished = true;
  },
  // 更新费用校验
  UPDATE_FREE_VALIDATE: (
    state: State,
    { key, res }: { key: string; res: boolean }
  ) => {
    if (res) {
      state.moneyValidate = {
        ...state.moneyValidate,
        [key]: res,
      };
    } else {
      delete state.moneyValidate[key];
    }
  },
  EDIT_Time_MODEL_VISIBLE: (state: State, data: boolean): void => {
    state.editTimeVisible = data;
  },
  CURRENT_ROUTE_DATA: (state: State, data: Record<string, any>): void => {
    state.currentRouteData = data;
  },
  UPDATE_MATCH_COUNT: (state: State): void => {
    GetMatchPoolCollect(state.scope).then((res: any) => {
      state.matchCount = {
        ...res,
      };
    });
  },
  UPDATE_SCOPE: (state: State, data: string): void => {
    state.scope = data;
  },
};

const actions = {
  updateValidate(context: any, data: Validate): void {
    context.commit("SET_VALIDATE", data);
  },
  updateModelVisible(context: any, data: boolean): void {
    context.commit("UPDATE_MODEL_VISIBLE", data);
  },
  updateModelVisibleInMatchCenter(
    context: any,
    data: { visible: boolean; grab: boolean }
  ): void {
    context.commit("UPDATE_MODEL_VISIBLE_IN_MATCH_CENTER", data);
  },
  updateMathCenterRefresh(context: any, data: boolean): void {
    context.commit("UPDATE_MATCH_CENTER_REFRESH", data);
  },
  updateSelectedSegments(context: any, data: any): void {
    context.commit("UPDATE_SEGMENT", data);
  },
  updateBoxSegmentData(
    context: any,
    data: { boxSegmentId: string; nodeType: SegmentNodeTypes }
  ): void {
    context.commit("UPDATE_BOX_SEGNMENT_DATA", data);
  },
  updateFreeConfig(context: any, data: FeeConfigInterface[]): void {
    context.commit("UPDATE_FREE_CONFIG", data);
  },
  updateFreeValidate(context: any, data: { key: string; res: boolean }): void {
    context.commit("UPDATE_FREE_VALIDATE", data);
  },
  // 编辑时间弹窗
  editTimeModelVisible(context: any, data: boolean): void {
    context.commit("EDIT_Time_MODEL_VISIBLE", data);
  },
  // 获取当前行数据
  getCurrentRouteData(context: any, data: Record<string, any>): void {
    context.commit("CURRENT_ROUTE_DATA", data);
  },
  updateMatchCount(context: any): void {
    context.commit("UPDATE_MATCH_COUNT");
  },
  updateScope(context: any, data: string): void {
    context.commit("UPDATE_SCOPE", data);
    context.commit("UPDATE_MATCH_COUNT");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
