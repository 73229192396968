import {
  OrderSegmentInterface,
  BoxInterface,
} from "xiangxin-element/dist/types/interface";
interface State {
  // 位置修改弹窗
  positionEditModelVisible: boolean;
  // 分派司机弹窗
  assignDriverModelVisible: boolean;
  // 当前选择的 箱子ID
  selectedBoxId?: number;
  // 箱子 位置ID
  positionId?: number | string;
  // 当前操作的箱子数据
  currentBox?: BoxInterface;
  // 段节点
  nodeType?: number | string;
  // 段数据
  segment?: OrderSegmentInterface;
}
const state: State = {
  positionEditModelVisible: false,
  assignDriverModelVisible: false,
  selectedBoxId: undefined,
};

const mutations = {
  UPDATE_POSITION_EDIT_MODEL_VISIBLE: (
    state: State,
    {
      visible,
      id,
      positionId,
    }: { visible: boolean; id: number; positionId: string }
  ): void => {
    state.positionEditModelVisible = visible;
    state.selectedBoxId = id;
    if (positionId !== "0") state.positionId = positionId;
  },
  UPDATE_POSITION_ID: (state: State, positionId: string): void => {
    state.positionId = positionId;
  },
  TOGGLE_ASSIGN_DRIVER_MODEL_VISIBLE: (state: State): void => {
    state.assignDriverModelVisible = !state.assignDriverModelVisible;
  },
  UPDATE_CURRENT_BOX: (state: State, currentBox: BoxInterface): void => {
    state.currentBox = currentBox;
  },
  UPDATE_SEGMENT_DATA: (
    state: State,
    {
      nodeType,
      segment,
    }: { nodeType: number | string; segment: OrderSegmentInterface }
  ): void => {
    state.nodeType = nodeType;
    state.segment = segment;
  },
};

const actions = {
  updateModelVisible(
    context: any,
    data: { visible: boolean; id: number; positionId: string }
  ): void {
    context.commit("UPDATE_POSITION_EDIT_MODEL_VISIBLE", data);
  },
  updatePositionId(context: any, positionId: string): void {
    context.commit("UPDATE_POSITION_ID", positionId);
  },
  toggleAssignDriverModelVisible(context: any): void {
    context.commit("TOGGLE_ASSIGN_DRIVER_MODEL_VISIBLE");
  },
  updateCurrentBox(context: any, currentBox: BoxInterface): void {
    context.commit("UPDATE_CURRENT_BOX", currentBox);
  },
  updateSegmentData(
    context: any,
    segment: { nodeType: number | string; segment: OrderSegmentInterface }
  ): void {
    context.commit("UPDATE_SEGMENT_DATA", segment);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
