import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dispatch/center",
  },
  {
    path: "/dispatch/center",
    name: "dispatch",
    component: () => import("../layout/routeMain.vue"),
    children: [
      // 箱列表 调度中心
      {
        path: "",
        name: "dispatchCenterDefault1",
        component: () => import("../views/DispatchCenter/dispatch/list.vue"),
      },
      // 箱列表 调度中心
      {
        path: "1",
        name: "dispatchCenterDefault2",
        component: () => import("../views/DispatchCenter/dispatch/list.vue"),
      },
      // 订单列表
      {
        path: "2",
        name: "OrderList",
        component: () => import("../views/DispatchCenter/order/index.vue"),
      },
      // 接单统计
      // {
      //   path: "3",
      //   name: "OrderStatistics",
      //   component: () => import("../views/DispatchCenter/orderStatistics/index.vue"),
      // },
      // // 订单详情
      {
        path: "order/detail/:id",
        name: "orderDetail",
        component: () => import("../views/orderDetail/index.vue"),
      },
      // 接单统计详情
      {
        path: "order/statistics/detail/:id/:scope",
        name: "orderStatisticsDetail",
        component: () => import("../views/orderStatisticsDetail/index.vue"),
      },
      // 卡券列表
      {
        path: "coupon/list/:id/:date",
        name: "couponList",
        component: () => import("../views/couponList/index.vue"),
      },
    ],
  },

  // 运力统计
  {
    path: "/transport/statistic",
    name: "transportStatistic",
    component: () => import("../views/Transport/index.vue"),
  },
  // 404
  { path: "/:pathMatch(.*)*", redirect: { name: "dispatchCenterDefault" } },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
