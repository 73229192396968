<template>
  <ElConfigProvider :locale="locale">
    <Layout> <router-view /> </Layout
  ></ElConfigProvider>
</template>
<script>
import Layout from "@/layout";
import { IconFont, Table } from "xiangxin-element";
import { ElConfigProvider } from "element-plus";

import zhCn from "element-plus/lib/locale/lang/zh-cn";
IconFont.register([
  "//at.alicdn.com/t/font_2859960_3szakr2flsk.js", // beluga
  "//at.alicdn.com/t/font_3258574_r7mair54z9e.js", // common
]);

import "xiangxin-element/dist/es/xiangxin-element.esm.css";
Table.setScrollContainer(".app-layout-content");
export default {
  components: {
    Layout,
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
}
</style>
