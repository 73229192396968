import { Directive, DirectiveBinding } from "vue";
type Config = { rows: number; offset: number };
/**
 * 文本超出隐藏
 *
 * rows: 行数
 * offset: 省略号偏移量（多去掉的字符个数）
 *
 */
export const ellipsis: Directive = {
  mounted(el, binding: DirectiveBinding<Config>) {
    const config = binding.value;
    const { rows = 1, offset = 0 } = config || {};
    const getComputedStyle =
      document.defaultView?.getComputedStyle || window.getComputedStyle;
    const str = el.innerHTML.trim();

    const totalTextLen = str.length;
    setTimeout(() => {
      const computedStyle = getComputedStyle(el, null);
      const width = computedStyle.width;
      const fontSize = computedStyle.fontSize;
      const lineWidth = +width.slice(0, -2);
      // 所计算的strNum为元素内部一行可容纳的字数(不区分中英文)
      const strNum = Math.floor(lineWidth / +fontSize.slice(0, -2));
      // 最终内容
      let content = "";
      // 多行可容纳总字数
      const totalStrNum = Math.floor(strNum * rows);
      const lastIndex = totalStrNum - totalTextLen;
      if (totalTextLen > totalStrNum) {
        content = str.slice(0, lastIndex - 1 - offset).concat("...");
      } else {
        content = str;
      }
      el.innerHTML = content;
    }, 0);
  },
};
