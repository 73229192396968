import { TransportColumnInterface } from "@/interface/transport";
interface State {
  visible: boolean;
  data: TransportColumnInterface | null;
}
const state: State = {
  visible: false,
  data: null,
};

const mutations = {
  SET_DATA: (state: State, data: TransportColumnInterface): void => {
    state.data = data;
  },
  UPDATE_MODEL_VISIBLE: (state: State, data: boolean): void => {
    state.visible = data;
  },
};

const actions = {
  updateData(context: any, data: TransportColumnInterface): void {
    context.commit("SET_DATA", data);
  },
  updateModelVisible(context: any, data: boolean): void {
    context.commit("UPDATE_MODEL_VISIBLE", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
