// import "element-plus/lib/theme-chalk/index.css";
import {
  // ElAffix,
  ElAlert,
  // ElAside,
  ElAutocomplete,
  // ElAvatar,
  // ElBacktop,
  // ElBadge,
  // ElBreadcrumbItem,
  // ElBreadcrumb,
  // ElButtonGroup,
  ElButton,
  //   ElCalendar,
  ElCard,
  //   ElCarouselItem,
  //   ElCarousel,
  ElCascaderPanel,
  ElCascader,
  //   ElCheckTag,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElCheckbox,
  ElCol,
  //   ElCollapseItem,
  //   ElCollapseTransition,
  //   ElCollapse,
  //   ElColorPicker,
  //   // ElConfigProvider,
  //   ElContainer,
  ElDatePicker,
  //   ElDescriptionsItem,
  //   ElDescriptions,
  ElDialog,
  ElDivider,
  //   ElDrawer,
  ElDropdownItem,
  ElDropdownMenu,
  ElDropdown,
  //   ElEmpty,
  //   ElFooter,
  ElFormItem,
  ElForm,
  //   ElHeader,
  ElIcon,
  //   ElImageViewer,
  ElImage,
  ElInfiniteScroll,
  ElInputNumber,
  ElInput,
  //   ElLink,
  ElLoading,
  //   ElMain,
  //   ElMenuItemGroup,
  //   ElMenuItem,
  //   ElMenu,
  ElMessageBox,
  ElMessage,
  ElNotification,
  //   ElOptionGroup,
  ElOption,
  ElPageHeader,
  ElPagination,
  ElPopconfirm,
  ElPopover,
  //   ElPopper,
  //   ElProgress,
  //   ElRadioButton,
  ElRadioGroup,
  ElRadio,
  //   ElRate,
  //   ElResult,
  ElRow,
  ElScrollbar,
  //   ElSelectV2,
  ElSelect,
  //   ElSkeletonItem,
  ElSkeleton,
  //   ElSlider,
  ElSpace,
  //   ElStep,
  //   ElSteps,
  //   ElSubmenu,
  ElSwitch,
  ElTabPane,
  ElTableColumn,
  ElTable,
  ElTabs,
  ElTag,
  //   ElTimePicker,
  //   ElTimeSelect,
  //   ElTimelineItem,
  //   ElTimeline,
  ElTooltip,
  //   ElTransfer,
  //   ElTree,
  //   ElUpload
} from "element-plus";

const components = [
  //   ElAffix,
  ElAlert,
  //   ElAside,
  ElAutocomplete,
  //   ElAvatar,
  //   ElBacktop,
  //   ElBadge,
  //   ElBreadcrumbItem,
  //   ElBreadcrumb,
  //   ElButtonGroup,
  ElButton,
  //   ElCalendar,
  ElCard,
  //   ElCarouselItem,
  //   ElCarousel,
  ElCascaderPanel,
  ElCascader,
  //   ElCheckTag,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElCheckbox,
  ElCol,
  //   ElCollapseItem,
  //   ElCollapseTransition,
  //   ElCollapse,
  //   ElColorPicker,
  //   // ElConfigProvider,
  //   ElContainer,
  ElDatePicker,
  //   ElDescriptionsItem,
  //   ElDescriptions,
  ElDialog,
  ElDivider,
  //   ElDrawer,
  ElDropdownItem,
  ElDropdownMenu,
  ElDropdown,
  //   ElEmpty,
  //   ElFooter,
  ElFormItem,
  ElForm,
  //   ElHeader,
  ElIcon,
  //   ElImageViewer,
  ElImage,
  ElInfiniteScroll,
  ElInputNumber,
  ElInput,
  //   ElLink,
  ElLoading,
  //   ElMain,
  //   ElMenuItemGroup,
  //   ElMenuItem,
  //   ElMenu,
  ElMessageBox,
  ElMessage,
  ElNotification,
  //   ElOptionGroup,
  ElOption,
  ElPageHeader,
  ElPagination,
  ElPopconfirm,
  ElPopover,
  //   ElPopper,
  //   ElProgress,
  //   ElRadioButton,
  ElRadioGroup,
  ElRadio,
  //   ElRate,
  //   ElResult,
  ElRow,
  ElScrollbar,
  //   ElSelectV2,
  ElSelect,
  //   ElSkeletonItem,
  ElSkeleton,
  //   ElSlider,
  ElSpace,
  //   ElStep,
  //   ElSteps,
  //   ElSubmenu,
  ElSwitch,
  ElTabPane,
  ElTableColumn,
  ElTable,
  ElTabs,
  ElTag,
  //   ElTimePicker,
  //   ElTimeSelect,
  //   ElTimelineItem,
  //   ElTimeline,
  ElTooltip,
  //   ElTransfer,
  //   ElTree,
  //   ElUpload
];

const plugins = [
  ElInfiniteScroll,
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
];

export default {
  install: function (app: Record<string, any>): void {
    components.forEach((component: any) => {
      app.component(component.name, component);
    });
    plugins.forEach((plugin) => {
      app.use(plugin);
    });
    const option = {
      size: "medium",
      zIndex: 2000,
    };
    app.use(ElLoading.directive);
    app.config.globalProperties.$ELEMENT = option;
  },
};
