<template>
  <div class="app-layout">
    <XiangxinMenu
      type="1"
      :domain="domain"
      @routeChange="callback"
    ></XiangxinMenu>
    <div class="app-layout-content-container">
      <xx-xiangxin-header></xx-xiangxin-header>
      <div class="app-layout-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { XiangxinMenu } from "xiangxin-webC-vue";
import { useRouter } from "vue-router";
const domain = process.env.VUE_APP_MOBY_DICK_SITE_URL;
const router = useRouter();
function callback(params) {
  router.push(params.detail);
}
</script>
<style lang="scss" scoped>
.app-layout {
  height: 100%;
  width: 100%;
  display: flex;

  .app-layout-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .app-layout-content {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
      padding: 13px;
    }
  }
}
</style>
