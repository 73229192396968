import { FeeConfigInterface } from "@/interface/segment";
import { SegmentItemInterface } from "xiangxin-element/dist/types/interface";
export function freeHandel(
  data: SegmentItemInterface[] | SegmentItemInterface,
  freeConfig: FeeConfigInterface[]
): SegmentItemInterface[] | SegmentItemInterface {
  if (Array.isArray(data)) {
    return data.map((item) => {
      moneyHandel(item, freeConfig);
      return item;
    });
  } else {
    return moneyHandel(data, freeConfig);
  }
}
function moneyHandel(
  data: SegmentItemInterface,
  freeConfig: FeeConfigInterface[]
) {
  if (Array.isArray(data.money_info) || !data.money_info) data.money_info = {};
  freeConfig.forEach((free) => {
    const { id, name, type } = free;
    if (!data.money_info[id]) {
      data.money_info[id] = {
        money: "",
        name,
        type,
      };
    } else {
      const money = data.money_info[id].money;
      if (!money || money == 0) {
        data.money_info[id].money = "";
      } else {
        data.money_info[id].money = Number(money) / 100 || 0;
      }
    }
  });
  return data;
}
